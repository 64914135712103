import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { TechHealthApi } from './TechHealthApi';

type TechHealthClientOptions = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

export class TechHealthClient implements TechHealthApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: TechHealthClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async getCSVExport(from: Date, to: Date): Promise<Blob> {
    const baseUrl = await this.discoveryApi.getBaseUrl('tech-insights');
    const url = new URL(`${baseUrl}/csv-export`);
    url.searchParams.set('from', from.toISOString());
    url.searchParams.set('to', to.toISOString());
    const request = new Request(url);

    const token = await this.getToken();
    if (token) {
      request.headers.set('authorization', `Bearer ${token}`);
    }

    const response = await fetch(request);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.blob();
  }

  private async getToken(): Promise<string | null> {
    const result = await this.identityApi.getCredentials();
    return result.token ?? null;
  }
}
