import { createApiRef } from '@backstage/core-plugin-api';

// This API implements extra API functions added on top of tech-insights plugin.
export const techHealthApiRef = createApiRef<TechHealthApi>({
  id: 'plugin.tech-health',
});

export interface TechHealthApi {
  getCSVExport: (from: Date, to: Date) => Promise<Blob>;
}
